import {graphql, StaticQuery} from 'gatsby'
import React from 'react'

import BlockImage from '../BlockImage'
import SectionText from '../SectionText'
import SectionLayout from '../SectionLayout'

import Timeline from './Timeline'
import styles from './AboutUs.module.css'

const query = graphql`
  query AboutUsQuery {
    section: sanityAboutUs(_id: {regex: "/(drafts.|)/"}) {
      headline,
      _rawText,
      image {
        asset {
          _id
        }
        alt,
        caption,
        underlay
      },
      items {
        date,
        event
      }
    }
  }
`

const Component = () => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          section: {
            headline,
            image,
            _rawText,
            items
          }
        } = data

        return (
          <>
            <SectionLayout
              headline={headline}
            >
              {image.asset && <div
                className={styles.img}
              >
                <BlockImage
                  image={image}
                />
              </div>}
              <div
                className={styles.text}
              >
                <SectionText
                  blocks={_rawText}
                />
              </div>
            </SectionLayout>
            <Timeline items={items} />
          </>
        )
      }}
    />
  )
}

export default Component
