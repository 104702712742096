import React from 'react'

import Icon from '../Icon'

import {getMinutes} from './utils'
import styles from './HarmonogramItem.module.css'
import {heightMultiplier} from './Harmonogram'

const HarmonogramItem = ({time, icon, event, offset}) => {
  return (
    <div
      className={styles.harmonogramItem}
      style={{top: (getMinutes(time) - offset) * heightMultiplier}}
    >
      <p
        className={styles.harmonogramTime}
      >
        {time}
      </p>
      <Icon
        image={icon}
        className={styles.harmonogramIcon}
      />
      <p>{event}</p>
    </div>
  )
}

export default HarmonogramItem
