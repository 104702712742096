import {graphql, StaticQuery} from 'gatsby'
import React from 'react'

import SectionText from '../SectionText'
import SectionHeadline from '../SectionHeadline'

import FaqItem from './FaqItem'
import styles from './FAQ.module.css'

const query = graphql`
  query FaqQuery {
    faq: sanityFaq {
    headline
    info: _rawInfo
    items: _rawItems
  }
  }
`

const renderFaq = (items) => items.map((item = {}) =>
  <FaqItem
    key={item._key}
    question={item.question}
    answer={item.answer}
  />
)

const Component = () => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          faq: {
            headline,
            info,
            items
          }
        } = data

        return (
          <div className={styles.root}>
            <SectionHeadline
              headline={headline}
            />
            <div className={styles.info} >
              <SectionText
                blocks={info}
              />
            </div>
            {renderFaq(items)}
          </div>
        )
      }}
    />
  )
}

export default Component
