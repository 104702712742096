import React from 'react'

import styles from './SectionLayout.module.css'
import SectionHeadline from './SectionHeadline'

const SectionLayout = ({headline, children}) => (
  <>
    {headline && <SectionHeadline headline={headline} />}
    <div className={styles.root}>{children}</div>
  </>
)

export default SectionLayout
