import {graphql, StaticQuery} from 'gatsby'
import React from 'react'

import BlockImage from '../BlockImage'
import SectionText from '../SectionText'
import SectionLayout from '../SectionLayout'

import styles from './OurDay.module.css'

const query = graphql`
  query OurDayQuery {
    section: sanityOurDay(_id: {regex: "/(drafts.|)/"}) {
      headline,
      _rawText,
      image {
        asset {
          _id
        }
        alt,
        caption,
        underlay
      }
    }
  }
`

const Component = () => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          section: {
            headline,
            image,
            _rawText
          }
        } = data
        return (
          <SectionLayout
            headline={headline}
          >
            <div
              className={styles.text}
            >
              <SectionText
                blocks={_rawText}
              />
            </div>
            <div
              className={styles.img}
            >
              <BlockImage
                image={image}
              />
            </div>
          </SectionLayout>
        )
      }}
    />
  )
}

export default Component
