import {graphql, StaticQuery} from 'gatsby'
import React from 'react'
import IndexLayout from '../components/Layout/IndexLayout'

const query = graphql`
  query IndexLayoutQuery {
    rsvpSettings: sanityRsvp(_id: {regex: "/(drafts.|)/"}) {
      weddingDate,
      rightQuote,
      leftQuote,
      rightQuote,
    }
    mainSettings: sanityMain(_id: {regex: "/(drafts.|)/"}) {
      items {
        route,
        title
      }
    }
  }
`

const LayoutContainer = (props) => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          rsvpSettings: {
            weddingDate,
            leftQuote,
            rightQuote
          } = {}
        } = data

        return (
          <IndexLayout
            {...props}
            weddingDate={weddingDate}
            leftQuote={leftQuote}
            rightQuote={rightQuote}
          />
        )
      }}
    />
  )
}

export default LayoutContainer
