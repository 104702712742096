import React from 'react'

import styles from './Timeline.module.css'

const Timeline = ({items = []}) => {
  return (
    <div className={styles.timeline}>
      <div className={styles.arrowContainer}>
        <span>{'>'}</span>
        <span>{'>'}</span>
        <span>{'>'}</span>
      </div>
      <div className={styles.timelineContainer} >
        {
          items.map((item = {}, index) => (
            <div className={styles.timelineItem} key={`timeline-item-${index}`}>
              <p className={styles.date}>{item.date}</p>
              <p className={styles.event}>{item.event}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Timeline
