import React from 'react'

import styles from './SectionHeadline.module.css'

const SectionHeadline = ({headline}) => (
  <div
    className={styles.container}
  >
    <h1 className={styles.root}>{headline}</h1>
  </div>
)

export default SectionHeadline
