import {graphql, StaticQuery} from 'gatsby'
import React from 'react'

import SectionText from '../SectionText'
import SectionHeadline from '../SectionHeadline'

import HarmonogramItem from './HarmonogramItem'
import styles from './Harmonogram.module.css'
import {getMinutes} from './utils'

export const heightMultiplier = 1.75

const query = graphql`
  query HarmonogramQuery {
    harmonogram: sanityHarmonogram {
      headline
      info: _rawInfo
      items: _rawItems
    }
  }
`

const renderHarmonogram = (items, offset) => items.map((item = {}) =>
  <HarmonogramItem
    key={item._key}
    time={item.time}
    icon={item.icon}
    event={item.event}
    offset={offset}
  />
)

const renderHours = (minutes) => {
  const marks = []
  let hour = true

  for (let i = 0; i <= minutes; i = i + 30) {
    marks.push(<div
      key={`mark-${i}`}
      className={styles.hourMark}
      style={{
        top: i * heightMultiplier,
        width: hour ? 32 : 16,
        left: hour ? 0 : 8
      }}
    />)
    hour = !hour
  }
  return marks
}

const Component = () => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          harmonogram: {
            headline,
            info,
            items = []
          }
        } = data

        const firstItem = items[0] || {}
        const lastItem = items[items.length - 1] || {}
        const timeHorizont = getMinutes(lastItem.time) - getMinutes(firstItem.time)
        const harmonogramHeight = (timeHorizont + 30)

        return (
          <div className={styles.root}>
            <SectionHeadline
              headline={headline}
            />
            <div className={styles.info} >
              {/* <SectionText
                blocks={info}
              /> */}
            </div>
            <div
              className={styles.harmonogramContainer}
              style={{height: harmonogramHeight * heightMultiplier}}
            >
              <div className={styles.line} />
              {renderHours(harmonogramHeight)}
              {renderHarmonogram(items, getMinutes(firstItem.time))}
            </div>
          </div>
        )
      }}
    />
  )
}

export default Component
