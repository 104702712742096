import React from 'react'

import styles from './FlexLayout.module.css'

const FlexLayout = ({children}) => (
  <div className={styles.greenContainer}>
    <div className={styles.root}>{children}</div>
  </div>
)

export default FlexLayout
