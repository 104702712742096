import React from 'react'

import {imageUrlFor} from '../lib/image-url'
import {buildImageObj, cn} from '../lib/helpers'

import styles from './BlockImage.module.css'

const BlockImage = (props) => {
  const {
    image
  } = props
  return (
    <div
      className={cn(styles.block, styles[image.underlay])}
    >

      {image && image.asset && <img
        className={styles.img}
        src={imageUrlFor(buildImageObj(image))
          .width(512)
          .height(340)
          .url()}
        alt={image.alt}
      />}
    </div>
  )
}

export default BlockImage
