import React from 'react'
import Helmet from 'react-helmet'

import IndexLayout from '../containers/IndexLayout'
import BasicInfo from '../components/BasicInfo/BasicInfo'
import AboutUs from '../components/AboutUs/AboutUs'
import OurDay from '../components/OurDay/OurDay'
import Harmonogram from '../components/Harmonogram/Harmonogram'
import FAQ from '../components/FAQ/FAQ'
import FlexLayout from '../components/FlexLayout'

const IndexPage = () => (
  <IndexLayout>
    <Helmet>
      <link rel='stylesheet' href='https://use.typekit.net/gsg0mqq.css' />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap" rel="stylesheet" />
    </Helmet>
    <BasicInfo />
    <OurDay />
    <AboutUs />
    <FlexLayout>
      <Harmonogram />
      <FAQ />
    </FlexLayout>
    {/* <BasicInfo />
    <AboutUs />
    <FlexLayout>
      <Harmonogram />
      <FAQ />
    </FlexLayout> */}
  </IndexLayout>
)

export default IndexPage
