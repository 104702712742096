import React, {useState} from 'react'

import {cn} from '../../lib/helpers'

import SectionText from '../SectionText'

import styles from './FaqItem.module.css'

const FaqItem = ({question, answer}) => {
  const [active, setActive] = useState(false)

  return (
    <>
      <button
        className={cn(styles.accordion, active && styles.active)}
        onClick={() => setActive(!active)}
      >
        {question}
      </button>
      <div className={cn(styles.panel, active && styles.active)}>
        <SectionText
          blocks={answer}
        />
      </div>
    </>
  )
}

export default FaqItem
