import React from 'react'

import BlockImage from './BlockImage'

import styles from './serializers.module.css'

const serializers = {
  types: {
    blockImage: ({node = {}}) => {
      return (
        <div className={styles.image}>
          <BlockImage image={node} />
        </div>
      )
    }
  },
  marks: {
    link: ({mark, children}) => {
      const {href} = mark
      return <a href={href} target='_blank' rel='noopener'>{children}</a>
    },
    colorOption: ({children = []}) => {
      return <span
        className={styles.color}
        style={{backgroundColor: children[0]}}
      />
    }
  }
}

export default serializers
