import {graphql, StaticQuery} from 'gatsby'
import React from 'react'

import Icon from '../Icon'
import SectionText from '../SectionText'

import styles from './BasicInfo.module.css'

// const query = graphql`
//   query BasicInfoQuery {
//     section: sanityBasicInfo(_id: {regex: "/(drafts.|)/"}) {
//       _rawText1,
//       _rawText2,
//       _rawText3,
//       icon1 {
//         asset {
//           _id
//         }
//         alt,
//       },
//       icon2 {
//         asset {
//           _id
//         }
//         alt,
//       },
//       icon3 {
//         asset {
//           _id
//         }
//         alt,
//       }
//     }
//   }
// `

const Component = () => {
  return (
  <div className={styles.container}>
    <div className={styles.basicInfoContainer}>
      <h1>02·09·2023</h1>
      <a href='https://mapy.cz/s/namevalovu' target='_blank'><h1>Statek Smrčiny</h1></a>
      <h1>11:30</h1>
    </div>
  </div>

  )
}

export default Component
