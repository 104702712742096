import React from 'react'
import IndexHeader from './IndexHeader'

import styles from './Layout.module.css'
import Footer from '../Footer/Footer'
import {cn} from '../../lib/helpers'

const IndexLayout = ({
  children,
  weddingDate,
  leftQuote,
  rightQuote
}) => (
  <>
    <IndexHeader
      weddingDate={weddingDate}
      leftQuote={leftQuote}
      rightQuote={rightQuote}
    />
    <div className={cn(styles.content, styles.indexContent)}>
      {children}
    </div>
    
    <footer className={styles.footer}>
      <Footer />
    </footer>
  </>
)

export default IndexLayout
