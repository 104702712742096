import React from 'react'

import PortableText from './portableText'
import styles from './SectionText.module.css'

const SectionText = ({blocks}) => (
  <div
    className={styles.root}
  >
    <PortableText blocks={blocks} />
  </div>
)

export default SectionText
